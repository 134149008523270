import React, { useEffect, useState } from 'react'
import {
  Box,
  Cell,
  Flex,
  Group,
  Spinner,
  Tag,
  TextButton,
  Token,
  VStack,
  Text,
  HStack,
  Avatar,
  Item,
  IconButton,
  ActionButton,
  Header,
  Popup,
  Button,
  Placeholder,
} from '@revolut/ui-kit'

import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  WorkScheduleEligibilityStepInterface,
  WorkScheduleEligibilityType,
} from '@src/interfaces/workSchedule'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { IdAndName } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { selectorKeys } from '@src/constants/api'
import { AudienceTable, TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import {
  getWorkScheduleEligibilityCustomFiltersWithoutHandling,
  useGetWorkScheduleEligibility,
} from '@src/api/workSchedule'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { PageActions } from '@components/Page/PageActions'
import { CommonTabsProps } from '@src/apps/People/TimeManagement/WorkSchedule/Edit/common'
import { useQuery } from '@src/utils/queryParamsHooks'
import { handleCustomFilters } from './helpers'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import Loader from '@components/CommonSC/Loader'

type Props = CommonTabsProps
export const WorkScheduleEligibility = ({ nextTabPath }: Props) => {
  const { query } = useQuery()
  const { values, submit } = useLapeContext<WorkScheduleEligibilityStepInterface>()

  const [isLoadingCustomFilters, setIsLoadingCustomFilters] = useState(false)
  const [customFilters, setCustomFilters] = useState<TableFilter>({})
  const [showSelectGroupPopup, setShowSelectGroupPopup] = useState(false)
  const [eligibilityGroupToAdd, setEligibilityGroupToAdd] = useState<IdAndName>()
  const [eligibilityGroupError, setEligibilityGroupError] = useState<string>()

  const { data: copySource, isLoading: isCopySourceLoading } =
    useGetWorkScheduleEligibility(query.copyFrom)

  useEffect(() => {
    if (query.copyFrom && copySource) {
      values.auto_assign_to_employees = copySource.auto_assign_to_employees
      values.custom_filters_eligibility_group =
        copySource.custom_filters_eligibility_group
      values.eligibility_group_type = copySource.eligibility_group_type
      values.eligibility_groups = copySource.eligibility_groups
    }
  }, [query.copyFrom, copySource])

  const loadCustomFilters = async (scheduleId: number, filterId: number) => {
    let filtersResponse
    try {
      filtersResponse = await getWorkScheduleEligibilityCustomFiltersWithoutHandling(
        scheduleId,
        filterId,
      )
    } catch (err) {
      if (err.response?.status === 404) {
        console.warn(
          'Could not find schedule eligibility custom filters data for an existing group',
        )
      } else {
        throw err
      }
    }
    return filtersResponse
  }

  useEffect(() => {
    if (
      values.eligibility_group_type?.id === 'employee_filters' &&
      values.custom_filters_eligibility_group?.id
    ) {
      setIsLoadingCustomFilters(true)
      loadCustomFilters(values.id, values.custom_filters_eligibility_group.id)
        .then(filtersResponse => {
          if (filtersResponse?.data?.table_filters) {
            setCustomFilters(filtersResponse.data.table_filters)
          }
        })
        .finally(() => setIsLoadingCustomFilters(false))
    }
  }, [
    values.id,
    values.eligibility_group_type?.id,
    values.custom_filters_eligibility_group?.id,
  ])

  useEffect(() => {
    setEligibilityGroupError(undefined)
  }, [values.eligibility_groups])

  if (isCopySourceLoading) {
    return <Loader />
  }
  return (
    <>
      <VStack space="s-16">
        <>
          <SectionTitle
            title="Who should be assigned to this schedule?"
            subtitle="Auto assign this work scheduled to any employee who meets the criterias set below"
          />
          <Cell p={0}>
            <VStack width="100%">
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Auto assign schedule to a group of employees',
                  description:
                    'Auto assign this work scheduled to any employee who meets the criteria set below',
                  avatar: <Avatar size={40} color={Token.color.blue} useIcon="People" />,
                }}
                name="auto_assign_to_employees"
                onAfterChange={async newValue => {
                  if (newValue && values.eligibility_group_type == null) {
                    values.eligibility_group_type = {
                      id: 'eligibility_group',
                      name: 'Eligibility group',
                    }
                  }
                }}
              />
            </VStack>
          </Cell>
        </>
        {values.auto_assign_to_employees && (
          <Box>
            <SectionTitle title="Who should we assign this schedule to?" />
            <LapeRadioSwitch<IdAndName<WorkScheduleEligibilityType>>
              name="eligibility_group_type"
              variant="horizontal"
              options={[
                {
                  id: 'eligibility_group',
                  label: (
                    <HStack space="s-8" align="center">
                      <Text>Groups</Text>
                      <Tag variant="faded">Recommended</Tag>
                    </HStack>
                  ),
                  description: (
                    <>
                      Assign employees meeting the eligibility criteria automatically to
                      this schedule. You can create groups{' '}
                      <TextButton to={ROUTES.APPS.GROUPS} use={InternalLink}>
                        here
                      </TextButton>
                      .
                    </>
                  ),
                  value: {
                    id: 'eligibility_group',
                    name: 'Eligibility group',
                  },
                },
                {
                  id: 'employee_filters',
                  label: 'Custom filters',
                  description:
                    'Assign eligibility for this schedule manually per employee.',
                  value: {
                    id: 'employee_filters',
                    name: 'Employee Filters',
                  },
                },
              ]}
            />
            <Group mt="s-16">
              {values.eligibility_group_type?.id === 'eligibility_group' && (
                <>
                  <Item>
                    <Item.Content>
                      <Item.Title>Target groups</Item.Title>
                      <Item.Description>
                        Whom should this schedule be applied to?
                      </Item.Description>
                    </Item.Content>
                    <Item.Side>
                      <ActionButton
                        useIcon="Plus"
                        onClick={() => setShowSelectGroupPopup(true)}
                      >
                        Add new
                      </ActionButton>
                    </Item.Side>
                  </Item>
                  <VStack width="100%" p="s-8">
                    {values.eligibility_groups?.length ? (
                      values.eligibility_groups.map(group => (
                        <Item key={group.id}>
                          <Item.Avatar>
                            <Avatar useIcon="People" />
                          </Item.Avatar>
                          <Item.Content>
                            <Item.Title>{group.name}</Item.Title>
                          </Item.Content>
                          <Item.Side>
                            <IconButton
                              useIcon="Delete"
                              color={Token.color.greyTone20}
                              onClick={() => {
                                values.eligibility_groups =
                                  values.eligibility_groups?.filter(
                                    addedGroup => addedGroup.id !== group.id,
                                  ) || []
                              }}
                            />
                          </Item.Side>
                        </Item>
                      ))
                    ) : (
                      <Placeholder>
                        <Placeholder.Image
                          image={{
                            default: `https://assets.revolut.com/assets/3d-images-v2/${
                              eligibilityGroupError ? '3D018' : '3D028'
                            }.png`,
                            '2x': `https://assets.revolut.com/assets/3d-images-v2/${
                              eligibilityGroupError ? '3D018' : '3D028'
                            }@2x.png`,
                            '3x': `https://assets.revolut.com/assets/3d-images-v2/${
                              eligibilityGroupError ? '3D018' : '3D028'
                            }@3x.png`,
                          }}
                        />
                        <Placeholder.Title
                          color={
                            eligibilityGroupError
                              ? Token.color.error
                              : Token.color.greyTone50
                          }
                        >
                          {eligibilityGroupError || 'No groups selected'}
                        </Placeholder.Title>
                        <Placeholder.Action>
                          <TextButton onClick={() => setShowSelectGroupPopup(true)}>
                            Add new
                          </TextButton>
                        </Placeholder.Action>
                      </Placeholder>
                    )}
                  </VStack>
                </>
              )}
              {values.eligibility_group_type?.id === 'employee_filters' && (
                <Box p="s-16">
                  {isLoadingCustomFilters ? (
                    <Cell>
                      <Flex width="100%" justifyContent="center">
                        <Spinner color={Token.color.blue} />
                      </Flex>
                    </Cell>
                  ) : (
                    <AudienceTable
                      filter={customFilters}
                      onFilterChange={newFilters => {
                        setCustomFilters(newFilters)
                      }}
                    />
                  )}
                </Box>
              )}
            </Group>
          </Box>
        )}
      </VStack>
      <Popup
        open={showSelectGroupPopup}
        onClose={() => setShowSelectGroupPopup(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>Select eligibility group</Header.Title>
        </Header>
        <RadioSelectInput<IdAndName>
          value={eligibilityGroupToAdd}
          selector={selectorKeys.dynamic_groups}
          label="Eligibility group"
          onChange={newGroup => {
            if (newGroup) {
              setEligibilityGroupToAdd(newGroup)
            }
          }}
        />
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={() => setShowSelectGroupPopup(false)}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => {
              if (eligibilityGroupToAdd) {
                const addedGroups = values.eligibility_groups || []
                values.eligibility_groups = [...addedGroups, eligibilityGroupToAdd]
                setEligibilityGroupToAdd(undefined)
              }
              setShowSelectGroupPopup(false)
            }}
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>

      <PageActions pt="s-64">
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          hideWhenNoChanges={false}
          onClick={async () => {
            await handleCustomFilters(values, customFilters)

            return submit()
          }}
          onAfterSubmit={res => {
            navigateReplace(pathToUrl(nextTabPath, { id: res.id }, query))
          }}
          errorHandler={e => {
            if (
              e.response?.status === 400 &&
              e.response.data.eligibility_groups?.length
            ) {
              setEligibilityGroupError(e.response.data.eligibility_groups[0])
            } else {
              throw e
            }
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
